@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.header {
  text-align: center;
  margin-bottom: 32px;

  h1 {
    margin-top: 0;
  }
}

.tarrifSectionWrap {
  padding: 0 80px 3em 80px;
  background-color: #fff;

  @include media-screen-down(sm) {
    padding: 1.5em 8px;
  }
}

.tariffInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 2em;
}

.subHeader {
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
}

.tariffNote {
  color: #194038;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;

  @media screen and (max-width: 1640px) {
    margin-bottom: 16px;
  }
}

.tariffNote {
  color: #194038;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;

  @media screen and (max-width: 1640px) {
    margin-bottom: 16px;
  }
}

.tariffCardsWrapper {
  cursor: pointer;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
}

.tariffCardsWrapper:last-child {
  margin-right: 0;
}

.infoIcon {
  width: 21px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  position: relative;
  top: 5px;

  svg {
    color: #00aca9;
  }
}

.tooltipWrap {
  max-width: 240px;
}
