@import '../node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

/* Tailwind _tailwind.scss is copy that comes from ui-lib */
// Tailwind directives for CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

$bgColor: #fff;

@layer base {
  :root {
    --color-primary: get-color('lkh-color-primary');
    --color-secondary: get-color('lkh-color-secondary');
    --color-neutral: get-color('lkh-color-neutral-100');
  }
}

// Responsive spacing
.p-res {
  @apply p-[32px] s:p-s m:p-m l:p-l xl:p-xl xxl:p-xxl;
  foo: bar;
}

.m-res {
  @apply m-[32px] s:m-s m:m-m l:m-l xl:m-xl xxl:m-xxl;
  foo: bar;
}

.layout-res {
  @apply py-[24px];
  background-color: $bgColor;
  foo: bar;
}

// ------------------------------------------------------------------------
// Grid helpers
.grid-mx-res {
  @apply mx-[15px] s:mx-m;
  foo: bar;
}

// Align grid vertically using same value as for gap
.grid-my-res {
  @apply my-[24px] s:my-[24px] m:my-[32px] l:my-[32px] xl:my-[32px] xxl:my-[32px];
  foo: bar;
}

.grid-gap-res {
  @apply gap-[24px] s:gap-s m:gap-m l:gap-l xl:gap-xl xxl:gap-xxl;
  foo: bar;
}
// ------------------------------------------------------------------------

// Responsive grid
.grid-res {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid;
  foo: bar;
}

.grid-res-1 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1;
  foo: bar;
}

.grid-res-2 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-2;
  foo: bar;
}

.grid-res-3 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-3;
  foo: bar;
}

.grid-res-4 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-4;
  foo: bar;
}

.grid-res-5 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-5;
  foo: bar;
}

.grid-res-6 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-6;
  foo: bar;
}

.grid-res-7 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-7;
  foo: bar;
}

.grid-res-8 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-8;
  foo: bar;
}

.grid-res-9 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-9;
  foo: bar;
}

.grid-res-10 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-10;
  foo: bar;
}

.grid-res-11 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-11;
  foo: bar;
}

.grid-res-12 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-12;
  foo: bar;
}

// Components (extracted classes for agent-portal responsive design adaptation)
.field-res {
  @apply flex flex-wrap items-stretch;
  foo: bar;
}

.yes-no-res {
  @apply mb-[12px];
  foo: bar;
}

.separator-res {
  @extend .grid-mx-res;
  foo: bar;
}

.person-data-offer-res {
  @apply mx-s;
  foo: bar;
}
