@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.questionWrapper {
  background-color: #fafafa !important;
}

.wrapperInner,
.questionWrapperInner {
  margin: 0 80px;
  @include media-screen-down(sm) {
    margin: 0;
    padding: 0 8px;
  }
}

.questionWrapperInner {
  background-color: #fff !important;
  padding: 32px 0;
}
