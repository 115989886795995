@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

$headerHeight: 70px;
$legalFooterHeight: 48px;
$legalFooterHeightSmall: 90px;
$bgColor: #fff;
$sidebarWidth: 446px;

.wizardLayout {
  display: grid;

  grid-template-columns: 6fr 6fr 440px;
  grid-template-rows: $headerHeight 10fr 1fr;

  grid-auto-flow: column;
  height: 100vh; // calc(100vh - #{$headerHeight} - #{$legalFooterHeight});

  grid-template-areas:
    'topNavigation topNavigation topNavigation'
    'main main rightPanel'
    'actions actions rightPanel'
    'legal legal legal';

  @include media-screen-down(lg) {
    grid-template-areas:
      'topNavigation'
      'main'
      'actions';

    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr auto 1fr;
  }

  @include media-screen-down(sm) {
    height: calc(100vh - #{$headerHeight} - #{$legalFooterHeightSmall});
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: 6fr 6fr $sidebarWidth;
  }
}

.topNavigation {
  grid-area: topNavigation;
}

.main {
  grid-area: main;
  overflow-y: auto;
  min-height: calc(100vh - 70px);

  @include media-screen-down(lg) {
    min-height: 721px;
  }

  @media screen and (min-width: 992px) {
    min-width: 692px;
  }
}

.actions {
  grid-area: actions;
  padding: 24px 80px;
  background: $bgColor;

  .actionButtons {
    display: flex;
    align-items: center;
  }
}

.rightPanel {
  grid-area: rightPanel;
  @apply bg-surface-60;
  foo: bar;
  min-width: $sidebarWidth;
  overflow-y: scroll;

  @media screen and (min-width: 992px) {
    max-height: 100%;
    overflow-y: scroll;
  }
}
